// Set site info
// var siteinfo = document.getElementsByName("siteinfo")[0];
// var site_id = siteinfo.getAttribute("data-siteid");
// var lang = siteinfo.getAttribute("data-sitelang");



$(document).ready(function ()
{
	/* correct clearen rc-person blokken */
	var person_counter = 0;

	$('.rc-block').each(function(index, element){
		var $block = $(element);
		console.log($block);
		console.log($block.hasClass('rc-person'));
		console.log(!$block.hasClass('rc-person--wide'));
		console.log(person_counter);

		if ($block.hasClass('rc-person') && !$block.hasClass('rc-person--wide')) {
			if(person_counter == 2) {
				$block.addClass('clear');
				person_counter = 0;
			}

			person_counter = person_counter+1;
		} else {
			person_counter = 0;
		}
	});



	/*
	 *	NAV
	 */

	$('.nav-toggler').click(function(){
		$('.nav--main').toggleClass('collapsed');

		if( $('.nav--main').hasClass('collapsed')) {
			$('.nav-toggler').html('Menu <i class="icon-menu"></i>');
		} else {
			$('.nav-toggler').html('Menu <i class="icon-cancel"></i>');
		}
	});


	// Youtube/Vimeo thumbs
	$("[class*='rc-video'] .thumb").click( startVideo );

	function startVideo(e) {
		var block = $(e.currentTarget);
		var iframe = $('iframe', block.parent());
		var src = iframe.attr('src');

		if (src.indexOf('autoplay=0') > -1) {
			iframe.attr('src', src.replace('autoplay=0','autoplay=1') );
		} else {
			if( src.indexOf('?') > -1 )
			{
				iframe.attr('src', src + '&autoplay=1');
			}
			else
			{
				iframe.attr('src', src + '?autoplay=1');
			}
		}
		block.fadeOut(150);
	}


	// Lightbox - UNCOMMENT ME BIJ KOPPELEN

	$('a.lightbox').fancybox({
		overlayOpacity: 0.8,
		overlayColor: 'black',
		padding: 0,
		titleShow: true
	});

	$('#top-alert').on('close.bs.alert', function()
	{
		var id = $(this).attr('data-id');
		document.cookie = "cookie_alert"+id+"=true";
	});

	//setup before functions
	var typingTimer;                //timer identifier
	var doneTypingInterval = 300;  //time in ms
	var $input = $('[data-search="input"]');

	//on keyup, start the countdown
	$input.on('keyup', function () {

		clearTimeout(typingTimer);
		var input = $(this);
		typingTimer = setTimeout(function()
		{
			doneTyping(input);
		}, doneTypingInterval);
	});

	//on keydown, clear the countdown
	$input.on('keydown', function () {
		clearTimeout(typingTimer);
	});

	//user is "finished typing," do something
	function doneTyping ($input) {
		var val = $input.val();

		if( val )
		{
			$.ajax({
				 method: "GET",
				 url: "/api/v2/"+site_id+'/'+lang+'/search',
				 data: { search: val, page: 1, itemsperpage: 3 }
			})
			.done(function( data ) {
				$input.parent().find('.search-hinting').remove();
				if( data.count > 0 )
				{
					result_html = '';
					$.each(data.result, function(index,result)
					{
						result_html += '<li><a href="'+result.url+'" title="'+result.seo_title+'"><div class="title">'+result.title+'</div><div class="intro">'+result.search_intro+'</div></a></li>';
					})

					$hinting_html = $('<div class="search-hinting"><ul>'+result_html+'<li><a data-search="submit" class="more">Toon alle resultaten ('+data.total+')</a></li>'+'</ul></div>');


					$input.parent().append($hinting_html);
				}
			});
		}
		else{
			$input.parent().find('.search-hinting').remove();
		}
	}

	$('body').on('click', '[data-search="submit"]',function()
	{
		$(this).closest("form").submit();
	})
});
